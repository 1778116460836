/* Error messages */
.error-msg {
  color: rgb(244, 67, 54);
  font-size: 12;
}

/* Success messages */
.success-msg {
  color: rgb(29, 184, 1);
  font-size: 12;
}

/* Required */
.required > label:after, .required > div > label:after {
  content: " *";
  color: rgb(244, 67, 54);
}
