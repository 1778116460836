/* Sticky footer: https://blog.hellojs.org/flexbox-sticky-footer-and-react-d116e4cfca5 */
:root {
  --space: 1.5em 0;
  --space: 2em 0;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.site-content {
  flex: 1 0 auto;
  padding: var(--space) var(--space) 0;
  width: 100%;
}

.site-content:after {
  content: '\00a0';
  display: block;
  margin-top: var(--space);
  height: 0;
  visibility: hidden;
}
/* End of sticky footer */

/* Footer styles */
.footer {
  background-color: #3B3B3B;
  margin-top: 5vh;
  padding: 5vh;
  text-align: center;
}
